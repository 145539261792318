import { css, cx } from '@emotion/css';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGetBaseUrl } from '@hooks';
import ellipseImage from '@public/images/png/ellipse.png';
import bolyMergingImage from '@public/images/svg/boly-merging.svg';
import dotFrameImage from '@public/images/svg/dot-frame.svg';
import { screenSize } from '@topremit/shared-web/common/size';
import {
  Container,
  Flex,
  parseHtml,
  ResponsiveImage,
  SegmentedTabs,
  Text,
} from '@topremit/shared-web/components/elements';
import { useMediaQuery, useTranslation } from '@topremit/shared-web/hooks';
import { IdentifierType } from '@topremit/shared-web/typings/auth.model';
import Image from 'next/image';
import Link from 'next/link';
import { NextSeo } from 'next-seo';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import LoginForm from './LoginForm';
import {
  FORGET_PASSWORD_PATH,
  initialValues,
  MAX_PHONE,
  MIN_PHONE,
} from './constants';
import { ILoginProps } from './types';

export default function Login() {
  const { baseUrl } = useGetBaseUrl();
  const { t, lang } = useTranslation('auth');
  const isTabletSize = useMediaQuery(`(max-width:${screenSize.tabletMd}px)`);

  const [identifierType, setIdentifierType] = useState<IdentifierType>(
    IdentifierType.EMAIL,
  );

  const identifierOptions = useGetIdentifierOptions();
  const validationSchema = useGetValidationSchema(identifierType);

  const methods = useForm<ILoginProps>({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema) as any,
    mode: 'onTouched',
  });

  const { clearErrors, reset } = methods;

  function handleOnToggle(value: IdentifierType) {
    clearErrors();
    reset(initialValues);
    setIdentifierType(value);
  }

  return (
    <>
      <NextSeo
        title={t('common:meta.login.title')}
        description={t('common:meta.login.description')}
        openGraph={{
          title: t('common:meta.login.title'),
          description: t('common:meta.login.description'),
          url: `${process.env.NEXT_PUBLIC_BASE_URL}/${lang}/login`,
        }}
      />
      <Flex as="section" className={styled.root}>
        <Flex className={styled.wrapper}>
          <Container>
            <Flex column className={styled.left}>
              <Flex column className={styled.formWrapper}>
                <Text as="h1" maxSize="h3" minSize="h5">
                  {t('login_page.title')}
                </Text>
                <Text
                  as="h2"
                  maxSize="p"
                  minSize="sm"
                  secondary
                  className={cx(styled.description, 'secondary-text')}
                >
                  {t('login_page.description')}
                </Text>

                <SegmentedTabs
                  options={identifierOptions}
                  onChange={handleOnToggle}
                >
                  {(identifier: IdentifierType) => (
                    <FormProvider {...methods}>
                      <LoginForm identifier={identifier} />
                    </FormProvider>
                  )}
                </SegmentedTabs>

                <Flex
                  column
                  align="center"
                  justify="center"
                  className={styled.additionalInfo}
                >
                  <Link href={FORGET_PASSWORD_PATH} prefetch={false}>
                    <Text className={cx('sm-bold-text')}>
                      {t('login_page.forgot_password')}
                    </Text>
                  </Link>
                  <Text className={styled.registerLink}>
                    {parseHtml(t('login_page.register', { baseUrl, lang }))}
                  </Text>
                </Flex>
              </Flex>
            </Flex>

            <Flex column className={styled.right}>
              <Flex column className={styled.illusWrapper}>
                <ResponsiveImage
                  ratio={4 / 3}
                  maxWidth={isTabletSize ? 200 : 500}
                  className="object-fit-contain"
                  src={bolyMergingImage}
                  alt={t('image_alt')}
                  dataNosnippet
                />
                <Text as="h4" maxSize="h3" minSize="sm">
                  {t('merging_dialog.title')}
                </Text>
                <Text maxSize="h5" minSize="sm">
                  {t('merging_dialog.desc')}
                </Text>
              </Flex>
            </Flex>
          </Container>
          <Flex className={styled.bgWrapper}>
            <Flex className="dot-up">
              <Image
                width={149}
                height={91}
                alt="login dot up"
                src={dotFrameImage}
              />
            </Flex>
            <Flex className="dot-down">
              <Image
                width={149}
                height={91}
                alt="login dot down"
                src={dotFrameImage}
              />
            </Flex>
            <Text className="circle-down">
              <Image
                width={503}
                height={711}
                alt="login dot circle"
                src={ellipseImage}
              />
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}

function useGetIdentifierOptions() {
  const { t } = useTranslation('auth');

  return [
    { label: t('email'), value: IdentifierType.EMAIL },
    { label: t('phone_number'), value: IdentifierType.PHONENUMBER },
  ];
}

function useGetValidationSchema(identifierType: IdentifierType) {
  const { t } = useTranslation('auth');

  return yup.object({
    ...(identifierType === IdentifierType.EMAIL
      ? {
          email: yup
            .string()
            .email(t('auth_field_validation.email'))
            .required(t('auth_field_validation.required')),
        }
      : {
          phoneNumber: yup
            .string()
            .required(t('auth_field_validation.required'))
            .min(MIN_PHONE, t('auth_field_validation.phone_number'))
            .max(MAX_PHONE, t('auth_field_validation.phone_number')),
        }),
    password: yup.string().required(t('auth_field_validation.required')),
  });
}

const styled = {
  root: css`
    min-height: calc(100vh - 4.375rem);
    .container {
      z-index: 9;
      display: flex;
      align-items: center;
      > div {
        align-items: center;
        justify-content: center;
        max-width: 50%;
        height: 100%;
      }
      @media (max-width: ${screenSize.desktopSm}px) {
        justify-content: center;
        padding-top: 4.625rem;
        padding-bottom: 4.625rem;
      }
      @media (max-width: ${screenSize.tabletMd}px) {
        flex-direction: column-reverse;
        > div {
          max-width: unset;
        }
      }
      @media (max-width: ${screenSize.mobileLg}px) {
        padding: unset;
      }
    }
  `,
  description: css`
    font-weight: var(--regular-font-weight);
    margin-bottom: 16px;
  `,
  wrapper: css`
    position: relative;
  `,
  left: css`
    @media (max-width: ${screenSize.mobileLg}px) {
      padding: 0 1.25rem 2rem;
      width: 100%;
    }
    .rounded-tab {
      margin-top: 1.25rem;
      margin-bottom: 1.5rem;
    }
    .form-group {
      margin-top: unset;
      margin-bottom: 1.5rem;
    }
  `,
  right: css`
    margin-left: 2rem;
    @media (max-width: ${screenSize.tabletMd}px) {
      position: relative;
      left: 0;
      right: 0;
      margin-left: unset;
      margin-bottom: 1.5rem;
      background: var(--blue-100);
      padding: 1rem 1.5rem;
    }
  `,
  formWrapper: css`
    max-width: 29.3125rem;
    @media (max-width: ${screenSize.tabletMd}px) {
      max-width: unset;
    }
  `,
  illusWrapper: css`
    text-align: center;
    align-items: center;
    transform: translateY(-10%);
    @media (max-width: ${screenSize.tabletMd}px) {
      transform: unset;
    }
  `,
  bgWrapper: css`
    position: absolute;
    height: 100%;
    @media (max-width: ${screenSize.tabletMd}px) {
      display: none;
    }
    > div {
      position: absolute;
      width: fit-content;
    }
    .dot-up {
      top: 0;
      right: 0;
    }
    .dot-down {
      bottom: 0;
      right: 40%;
    }
    .circle-down {
      bottom: 0;
      right: 0;
    }
  `,
  registerLink: css`
    color: var(--text-secondary);
    font-size: 0.875rem;
    a {
      font-weight: bold;
    }
  `,
  additionalInfo: css`
    > a {
      margin-bottom: 2.5rem;
    }
  `,
};
