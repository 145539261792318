import {
  AccountInfoBusiness,
  AccountType,
  KycStatus,
  useGetAccountInfo,
  useGetMe,
} from '@api-hooks/common';
import { queryClient } from '@common/client';
import { AuthorizationRules } from '@common/rules';
import { SHOW_BLOCKING_MODAL } from '@common/storage';
import { deleteAllLocalToken, usePermission } from '@hooks';
import type { PageWithLayoutType } from '@layouts';
import { useCurrentAccountStore } from '@stores';
import { AUTH_FLAG_KEY } from '@topremit/shared-web/common/cookie';
import { getItemInCookies } from '@topremit/shared-web/common/helper';
import { isNativeApp } from '@topremit/shared-web/common/native-web-view-bridge';
import { useNotification, useTranslation } from '@topremit/shared-web/hooks';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';

export enum PageRoutingRule {
  LOGIN_FROM_AFFILIATE = 'LOGIN_FROM_AFFILIATE',
  VERIFY_PHONE_NUMBER = 'VERIFY_PHONE_NUMBER',
  REDIRECT_AFFILIATE_REQUEST_FORM = 'REDIRECT_AFFILIATE_REQUEST_FORM',
  REDIRECT_TO_BECOME_AFFILIATE = 'REDIRECT_TO_BECOME_AFFILIATE',

  // business account type
  AFFILIATE_BLOCKING_MODAL = 'AFFILIATE_BLOCKING_MODAL',
}

export function withPageRoutingRule(
  Component: PageWithLayoutType,
  rules: PageRoutingRule[],
) {
  WithPageRoutingRule.pageLayout = Component.pageLayout;

  if ('pageLayoutConfig' in Component) {
    WithPageRoutingRule.pageLayoutConfig = Component.pageLayoutConfig;
  }

  function WithPageRoutingRule() {
    const router = useRouter();
    const { t } = useTranslation();
    const { data: meData, isFetched: isFetchedMe } = useGetMe();
    const me = meData?.data;
    const { data: accountInfo, isFetched: isFetchedAccountInfo } =
      useGetAccountInfo<AccountInfoBusiness>();
    const { addNotification } = useNotification();
    const [isAllowed, setAllowed] = useState<boolean>();

    const { isPersonalAccount, isBusinessAccount, can } = usePermission();

    const hasAuthFlagKey = !!getItemInCookies(AUTH_FLAG_KEY);

    const isAuthenticated = hasAuthFlagKey;

    const isAffiliateBlocked =
      isBusinessAccount &&
      (accountInfo?.data.kycStatus?.value !== KycStatus.DATA_APPROVED ||
        !can(AuthorizationRules.MenusAffiliate));

    const isPhoneNumberVerified = me && me?.activated;

    const isAffiliate = me && me?.isAffiliate;

    const ruleOptions = {
      // **PERSONAL ACCOUNT**
      isAuthenticated,
      isPhoneNumberVerified,
      isAffiliate,

      // **BUSINESS ACCOUNT**
      isAffiliateBlocked,
    };

    const pageRoutingRuleFn = useMemo(
      () => ({
        /**
         * rule to redirect user to /verify/phone-number when authenticated and number is not verified
         */
        [PageRoutingRule.VERIFY_PHONE_NUMBER]({
          isAuthenticated: _isAuthenticated,
          isPhoneNumberVerified: _isPhoneNumberVerified,
        }) {
          const { currentAccount } = useCurrentAccountStore.getState() || {};

          if (
            _isAuthenticated &&
            _isPhoneNumberVerified === false &&
            currentAccount?.type === AccountType.PERSONAL
          ) {
            router.push('/verify/phone-number');

            return false;
          }
          return true;
        },
        /**
         * rule to redirect user from /affiliate-request-form to /affiliate when authenticated and already become affiliate
         */
        [PageRoutingRule.REDIRECT_AFFILIATE_REQUEST_FORM]({
          isAuthenticated: _isAuthenticated,
          isAffiliate: _isAffiliate,
        }) {
          if (_isAuthenticated && _isAffiliate) {
            router.push('/affiliate');
            addNotification({
              message: t('affiliate-dashboard:redirect_toast'),
              type: 'success',
              closeable: false,
            });
            return false;
          }
          return true;
        },
        /**
         * rule to redirect user to /become-affiliate when user access pages while authenticated and not yet become affiliate
         */
        [PageRoutingRule.REDIRECT_TO_BECOME_AFFILIATE]({
          isAuthenticated: _isAuthenticated,
          isAffiliate: _isAffiliate,
        }) {
          if (_isAuthenticated && !_isAffiliate) {
            if (isNativeApp()) {
              router.replace('/become-affiliate');
              return false;
            }
            router.push('/become-affiliate');
            return false;
          }
          return true;
        },
        /**
         * rule to redirect user to /profile when user isn't allowed to access affiliate features
         */
        [PageRoutingRule.AFFILIATE_BLOCKING_MODAL]({
          isAuthenticated: _isAuthenticated,
          isAffiliateBlocked: _isAffiliateBlocked,
        }) {
          if (_isAuthenticated && _isAffiliateBlocked) {
            window.sessionStorage.setItem(SHOW_BLOCKING_MODAL, 'true');
            router.push('/profile');
            return false;
          }
          return true;
        },
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        isAuthenticated,
        router,
        isAuthenticated,
        isPhoneNumberVerified,
        isAffiliate,
        isAffiliateBlocked,
      ],
    );

    useEffect(() => {
      if (!hasAuthFlagKey) {
        queryClient.removeQueries('me');
        queryClient.removeQueries('account-info');
        deleteAllLocalToken();
      }
    }, [hasAuthFlagKey]);

    useEffect(
      function checkForAllRule() {
        // Note: check is CSR
        if (!isFetchedAccountInfo) {
          return;
        }

        if (isPersonalAccount && !isFetchedMe) {
          return;
        }

        if (
          isBusinessAccount &&
          accountInfo?.data.kycStatus?.value === KycStatus.DATA_APPROVED &&
          !isFetchedMe
        ) {
          return;
        }

        if (isAuthenticated) {
          setAllowed(false);
        }

        const isPassedAllRules = rules.every((rule) =>
          pageRoutingRuleFn[rule](ruleOptions),
        );

        setAllowed(isPassedAllRules);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        accountInfo?.data?.kycStatus,
        me?.activated,
        me?.bankAccountVerificationInfo?.matchingStatus,
        me?.kycStatus,
        isFetchedAccountInfo,
        isFetchedMe,
      ],
    );

    if (isAllowed === false) {
      return null;
    }

    return <Component />;
  }

  return WithPageRoutingRule;
}
