import { useTranslation } from '@topremit/shared-web/hooks';

/**
 * Custom hook to get the base URL.
 *
 * @returns {Object} An object containing the following:
 *   @property {string} baseUrl - The base URL of the application based on the environment.
 *   @property {string} baseUrlWithLocale - The base URL with the current language locale appended.
 *
 * @example
 * // In development, with `lang = 'en'` and `process.env.PORT = 3000`:
 * // {
 * //   baseUrl: 'http://localhost:3000',
 * //   baseUrlWithLocale: 'http://localhost:3000/en'
 * // }
 *
 * // In production, with `lang = 'en'` and `NEXT_PUBLIC_BASE_URL = 'https://topremit.com'`:
 * // {
 * //   baseUrl: 'https://topremit.com',
 * //   baseUrlWithLocale: 'https://topremit.com/en'
 * // }
 */
function useGetBaseUrl() {
  const { lang } = useTranslation();

  const baseUrl =
    process.env.NODE_ENV === 'development'
      ? `http://localhost:${process.env.PORT || 3000}`
      : process.env.NEXT_PUBLIC_BASE_URL;

  const baseUrlWithLocale = `${baseUrl}/${lang}`;

  return { baseUrl, baseUrlWithLocale };
}

export default useGetBaseUrl;
