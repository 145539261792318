
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { withPageRoutingRule } from '@hoc';
import { Login } from '@modules';
function LoginPage() {
    return <Login />;
}
LoginPage.pageLayoutConfig = {
    pageViewType: 'Both',
};
const __Next_Translate__Page__1961e68852c__ = withPageRoutingRule(LoginPage, []);

    export default __appWithI18n(__Next_Translate__Page__1961e68852c__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
    });
  