export const MIN_PHONE = 10;
export const MAX_PHONE = 17;
export const FORGET_PASSWORD_PATH = '/forget-password';
export const REGEX_EMAIL =
  /^([a-zA-Z0-9_.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
export const STRING_REPLACE = 'The identifier';

export const initialValues = {
  email: '',
  phoneNumber: '',
  password: '',
};

export const variants = {
  hidden: {
    opacity: 0,
    transition: {
      opacity: { duration: 0.3 },
    },
  },
  unhidden: {
    opacity: 1,
    transition: {
      opacity: { duration: 0.5 },
    },
  },
  left: {
    x: 80,
    transition: {
      x: { duration: 0.1 },
    },
  },
  right: {
    x: 0,
    transition: {
      x: { duration: 0.1 },
    },
  },
};
