import { useGetCountries } from '@api-hooks/common';
import { css } from '@emotion/css';
import { useAnalytics, useAuth } from '@hooks';
import {
  Button,
  PasswordInput,
  PhoneNumberInputV2,
  TextInput,
} from '@topremit/shared-web/components/elements';
import { useTranslation } from '@topremit/shared-web/hooks';
import { IdentifierType } from '@topremit/shared-web/typings/auth.model';
import { useFormContext } from 'react-hook-form';

import { ILoginProps } from './types';

interface Props {
  identifier: IdentifierType;
}

export default function LoginForm({ identifier }: Props) {
  const { t } = useTranslation('auth');

  const {
    analyticsPersonal: { tracksPersonal },
    analyticsBusiness: { tracksBusiness },
  } = useAnalytics();

  const { control, handleSubmit } = useFormContext<ILoginProps>();

  const { data: countries, isLoading: isLoadingCountries } = useGetCountries(
    {},
    {
      enabled: identifier === IdentifierType.PHONENUMBER,
    },
  );

  const { loginQuery, login } = useAuth();

  const isLoadingBtnSubmit = loginQuery?.isLoading;
  const isDisabledField = loginQuery?.isLoading || isLoadingCountries;
  const isDisabledBtnSubmit = isLoadingCountries;

  async function onSubmit(values: ILoginProps) {
    const { email, password, phoneNumber } = values;
    const identifierValue =
      identifier === IdentifierType.EMAIL ? email : phoneNumber;

    try {
      await login({
        password,
        type: identifier,
        identifier: identifierValue,
      });

      tracksPersonal?.login({ method: identifier });
      tracksBusiness?.login({ method: identifier });
    } catch (error) {
      tracksPersonal?.loginSubmitFailed({
        error: error.message,
        source: 'ON_SUBMIT_LOGIN_SCREEN',
        method: identifier,
      });
      tracksBusiness?.loginSubmitFailed({
        error: error.message,
        source: 'ON_SUBMIT_LOGIN_SCREEN',
        method: identifier,
      });
    }
  }

  return (
    <form method="POST" onSubmit={handleSubmit(onSubmit)}>
      {identifier === IdentifierType.EMAIL ? (
        <TextInput
          name="email"
          className="email"
          label={t('email')}
          disabled={isDisabledField}
        />
      ) : (
        <PhoneNumberInputV2
          control={control}
          name="phoneNumber"
          label={t('phone_number')}
          disabled={isDisabledField}
          dialCodeOptions={countries?.data}
          defaultValue={{
            dialCodeCountry: 'IDN',
          }}
        />
      )}
      <PasswordInput
        name="password"
        label={t('password')}
        disabled={isDisabledField}
      />
      <Button
        fullWidth
        htmlType="submit"
        className={styled.btnLogin}
        isLoading={isLoadingBtnSubmit}
        disabled={isDisabledBtnSubmit}
        onClick={handleSubmit(onSubmit)}
      >
        {t('login')}
      </Button>
    </form>
  );
}

const styled = {
  btnLogin: css`
    margin-bottom: 1.5rem;
  `,
};
